$(function () {
  function updateCountdown() {
    $('[data-enddate]').each(function () {
      const endDate = new Date($(this).data('enddate')); // Fecha límite en UTC
      const now = new Date(); // Hora actual en la zona horaria del navegador

      const diff = endDate - now; // Diferencia entre la fecha límite y la actual

      if (diff <= 0) {
        $('#discount-nav').remove();
        return;
      }

      const days = Math.floor(diff / (1000 * 60 * 60 * 24));
      const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((diff % (1000 * 60)) / 1000);

      let countdownText = '';
      if (days > 0) countdownText += `${days}d `;
      if (hours > 0 || days > 0) countdownText += `${hours}h `;
      if (minutes > 0 || hours > 0 || days > 0) countdownText += `${minutes}m `;
      countdownText += `${seconds}s`;

      $(this).text(countdownText.trim());
    });
  }

  setInterval(updateCountdown, 1000);
  updateCountdown();
});
